// Fonts
$font-family-body: "Menlo", monospace;
$font-family-mono: "Menlo", monospace;
$font-family-tt: "Menlo", monospace;
$font-size: 14px;
$line-height: 1.5;
$page-width: 48rem;
// Logo
$logo-width: 40px;
$logo-height: 40px;
$logo-grayout: true;
// Colors
$colors: "classic" // white dark light classic